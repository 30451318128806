* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
  }
  
  a {
    color: unset;
    text-decoration: none;
  }

  img {
    -webkit-user-drag: none;
  }
  
  textarea:focus, input:focus{
    outline: none;
  }
  iframe {
    pointer-events: none;
  }
  .form-heading {
    color: #475569;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .disabled-resize {
    resize: none;
  }
  
  